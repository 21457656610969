<template>
  <!-- 상품 상세 페이지 -->
  <div v-if="themeCard">
    <!-- 상단 -->
    <div class="container" style="margin-top: 100px;overflow-x: hidden;overflow-y:hidden">
      <!--헤더부분-->
      <div class="columns is-multiline">
        <div class="column is-8">
          <div class="columns is-multiline">
            <!-- 이미지 -->
            <div class="column is-6" :class="{'img': !mainCard}" v-if="mainCard">
              <!--<img :src="mainCard" alt="" style="border-radius: 3px;">-->
              <div class="card-img" :style="imgField(mainCard)"></div>
            </div>

            <!-- 상세 정보 -->
            <div class="column is-6 lp-store-detail">
              <div class="theme-card">
                <div class="theme-card-form">
                  <span>테마</span>
                  <img src="../../assets/gray-arrow-right.png"
                       style="width:10px;height:10px;margin: 0 4px;">
                  <span>{{ themeCard.cardType }}</span>
                </div>
                <div class="card-title">{{ themeCard.title }}</div>
                <div class="card-comment">{{ themeCard.content }}</div>
                <div class="card-label">
                  <span class="card-available pc"
                        v-if="themeCard.available">{{ themeCard.available }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- 가격 -->
        <div class="column is-4 lp-store-price-detail">
          <div class="product-price">

            <!-- 할인 가격 -->
            <div v-if="themeCard.is_discount">
              <div class="discount-price">
                <span class="price">{{ themeCard.price | currencyNum }}</span>{{ discountPrice(themeCard) | currency }}<span v-if="themeCard.price_type===1">/월</span></div>
              <div class="discountTitle flex">{{ themeCard.discount_title }}</div>
            </div>
            <!-- 일반 가격 -->
            <div v-else>
              <div class="discount-price flex">{{ themeCard.price | currency }}<span v-if="themeCard.price_type===1">/월</span></div>
            </div>
            <div class="counselling">* 신청 및 상담 후 이용 확정 및 결제 후 개발 진행</div>

            <div class="mobile flex" style="justify-content: flex-end;padding-top: 34px;">
              <span class="card-available mobile" v-if="themeCard.available">{{ themeCard.available }}</span>
            </div>

            <!-- pc -->
            <div class="pc">
              <div class="lp-btn-group" style="cursor:pointer">
                <div class="product-sub" @click="clickRequest">
                  <span v-if="themeCard.available">이용 신청하기</span>
                  <span v-else>제작 신청하기</span>
                </div>
              </div>
              <div class="lp-btn-group"
                   style="cursor:pointer">
                <a :href="themeCard.link" target="_blank" @click="clickDemo"><div class="product-demo">테마 서비스 데모</div></a>
              </div>
            </div>
            <!-- mobile -->
            <div class="mobile lp-floating">
              <div class="flex" style="height: 100%;padding: 6px;">
                <div class="lp-floating-menu" @click="clickRequest">
                  <span v-if="themeCard.available">이용하기</span>
                  <span v-else>제작 신청하기</span>
                </div>
                <div class="lp-floating-menu">
                  <a :href="themeCard.link" target="_blank" style="color:#ff6600">데모 바로가기</a>
                </div>
              </div>

            </div>
          </div>
          <div>
          </div>
        </div>
      </div>

      <!-- 섹션 부분 -->
      <div class="columns is-multiline" style="padding-bottom:80px;padding:12px" v-if="showAnchor">
        <!-- 탭 -->
        <div class="lp-tabs column is-full">
          <anchor-tab :menu="[{id:0,name:'서비스 안내'},{id:1,name:'관리솔루션'},{id:2,name:'가격'},{id:3,name:'이용문의'},{id:4,name:'FAQ'}]"
                      :headerHeight="48"
                      :scrollOffset="computedTabTop"
                      :anchorHeadStyle="anchorHeadStyle"
                      :minOffset="1091">
            <!--<div class="lp-tab" @click="clickTab(1, '서비스 안내')" :class="[activetab===1?'active':'']">서비스 안내</div>
            <div class="lp-tab" @click="clickTab(2, '관리솔루션')" :class="[activetab===2?'active':'']">관리솔루션</div>
            <div class="lp-tab" @click="clickTab(3, '가격정보')" :class="[activetab===3?'active':'']">가격정보</div>
            <div class="lp-tab" @click="clickTab(4, '이용문의')" :class="[activetab===4?'active':'']">이용문의</div>
            <div class="lp-tab" @click="clickTab(5, '테마 FAQ')" :class="[activetab===5?'active':'']">테마 FAQ</div>-->

            <div slot="content">
              <!-- activetab===1 -->
              <div v-if="themeCard.imgs && themeCard.imgs.length>0" class="content column is-12">
                <div class="content-slide" v-if="themeCard.imgs" :style="contentSlideStyle">
                  <slick ref="slick" :options="slickOptions" id="slick-images">
                    <div v-for="(img, idx) in themeCard.imgs"
                         :key="'slide-'+idx" class="slick-img-outer">
                      <img :src="img" alt="" class="slick-img" :style="slickImg">
                    </div>

                  </slick>
                </div>
              </div>

            <!-- 탭 1 상세 정보  v-if="activetab===1" -->

              <div class="content column is-12" id="anchor0" v-html="themeCard.info">{{ themeCard.info }}</div>

              <div class="content column is-12" id="anchor1">
                <div v-html="themeCard.common_info.use_info"></div>
              </div>

              <div class="content column is-12" id="anchor2">
                <div v-html="themeCard.common_info.delivery_info"></div>
              </div>


              <!-- 탭 2 문의 글 -->
              <div class="content column is-12" id="anchor3">
                <div>
                  <div class="columns is-multiline" style="margin-top: 12px;">
                    <div class="column is-6 lp-allcount">{{ allCount }}개의 문의가 있습니다</div>
                    <div class="column is-6">
                      <div class="search-div flex">
                        <input class="search"
                               type="text"
                               placeholder="검색어를 입력해주세요"
                               v-model="filtered.search_keyword"
                               @input="searchInquiry">
                        <img src="../../assets/search.png" class="search-icon">
                      </div>
                    </div>
                  </div>

                  <div class="response-container" v-for="(inquiry,idx) in themeInquiry" :key="'theme-inquiry-'+idx">
                    <div class="response-wrap unselect" @click="clickInquiry(inquiry)">
                      <!-- 문의한사람 -->
                      <div class="response-box">
                        <div class="flex" style="justify-content: space-between">
                          <div class="flex" style="align-items: center">
                            <span class="user-name"><span v-for="(name,idx) in inquiry.author.slice(0,2)" :key="'author-'+idx">{{ name }}</span>*</span>
                            <span class="dateTime" style="margin-left:8px">{{ inquiry.created_time }}</span>
                          </div>
                          <div>
                            <span class="answer-box" :class="{'answer1': !inquiry.answerer,'answer2': inquiry.answerer}">{{ contentAnswer(inquiry.answerer) }}</span>
                          </div>
                        </div>
                        <div>{{ inquiry.question_content }}</div>
                      </div>

                      <!-- 답변자 -->
                      <div class="admin-response-box" v-if="inquiry.selected">
                        <div>
                          <span class="user-name">{{ inquiry.answerer }}</span>
                          <span class="dateTime" style="margin-left:8px">{{ inquiry.answered_time }}</span>
                        </div>
                        <div v-if="inquiry.answer_content">{{ inquiry.answer_content }}</div>
                      </div>
                    </div>
                  </div>
                  <!-- 페이지네이션 -->
                  <lp-paginate :filtered="filtered"></lp-paginate>

                  <div>
                    <div class="writeInquire">문의 남기기</div>
                    <textarea class="textarea"
                              placeholder="문의글을 작성해주세요."
                              v-model="writeInquiry.question_content"
                              @focus="checkInquiry"
                              maxlength="500"></textarea>
                    <div class="flex" style="justify-content: flex-end;margin-top:12px">
                      <div class="submit-btn unselect" @click="saveProductInquiry">문의제출</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="content column is-12" id="anchor4">
                <div v-html="themeCard.common_info.refund_info"></div>
              </div>

              <!-- 맞춤 디비 -->
              <div class="content column is-12">
<!--                <div class="cardNum">테마 이용 신청수   {{ themeCard.cardNum }}</div>-->
                <div>
                  <div class="supply" style="padding:12px">
                    <template v-for="(param,idx) in themeCard.params">
                      <div  style="margin-bottom:40px" v-if="param.param_type===0" :key="'theme-card-'+idx">
                        <div style="margin-bottom:12px">{{ param.name }}</div>
                        <div v-html="param.value_text">{{ param.value_text }}</div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>

              <div class="content column is-12">
                <div class="theme-rule">
                  [취소/환불 정책]
                  서비스를 공급받은 날부터 7일 이내 취소/환불 가능
                  [ 테마 ]
                  - 테마 구매 시 지정하신 한 도메인에 대해서만 사용권을 드립니다. (1Copy, 1Domain, 1DataBase, 1Host)
                  - 여러 도메인에 설치하실 경우 각각 테마 라이선스를 구매하셔야 합니다.
                  - 물론 단순 포워딩이나 리다이렉션은 상관이 없습니다.
                  - 런치팩 테마의 사용권을 구매하는 것이며 프로그램의 소유권, 저작권에 대한 구매가 아닙니다.
                  - 내려받은 테마를 상업적 목적을 위하여 파생 작업을 복제, 배포, 전시, 공개적으로 실행, 준비하거나 사용하는 것은 명시적으로 금지됩니다.
                  - 나아가 테마의 사본을 재인가하거나, 판매, 대여 또는 그 밖의 방법으로 배포하는 것을 제한합니다.
                  - 허가(제휴)가 되지 않은 허가되지 않은 웹사이트에 대한 테마 상품진열은 불가합니다. (이미지/데모 등 콘텐츠 일체)
                  - 개발사는 테마의 관리 의무가 없으며, 설치 후 운용 및 관리의 책임은 사용자에게 있습니다.

                  [무료이미지]
                  - 이미지는 테마 제작사인 ‘런치팩’의 테마를 이용하여 웹사이트를 제작하는 경우에만 사용하도록 사용권을 제한하고 있으며
                  이미지 소스를 사용하여 편집, 수정, 변형, 재가공 등 디자인 작업에 활용할 수 있습니다.
                  - 이미지는 ‘런치팩 테마’를 구매한 당사자만 사용할 수 있으며, 제3자에게 판매, 양도, 대여, 사용허락 등을 하는 행위는 허락되지 않습니다.
                  - 이미지의 다운로드 권한을 가졌다고 해서 지적 재산권인 소유권을 가진 것은 아니며, 제한적인 사용권만 허락되어 집니다.
                  - 이미지를 다수의 사용자를 위한 디자인 템플릿이나 홈페이지 스킨 또는 응용 프로그램에 포함하여 배포할 수 없습니다.
                  - Social Network Service(SNS), 미니홈피, 블로그, 홈페이지 빌더, 쇼핑몰 빌더 등 불특정 다수에게 배포하는 홈페이지에 사용할 경우 협의없이 민형사상 책임을 지게 됩니다.
                  - 이미지를 원본 그대로 사용하는 것을 허용하고 있으나, 이미지 내에 인물사진이 포함된 경우는 제외되며, 실제 사용 시 인물 부분은 편집하여 사용하여야 하며, 이에 대한 법적 문제를 책임져야 하고 저작권자의 권한으로 사용권을 박탈할 수 있습니다.
                  - 이미지는 ‘런치팩 테마’를 구매하여 사이트 제작을 하는 당사자만 작업이 허용되며, 타 테마, 타 호스팅에서 사용 할 경우 불법사용 적발시 민형사상 책임을 지게 됩니다.
                </div>
              </div>
            </div>
          </anchor-tab>
          <!--<div class="flex">

            <div style="width:100%;border-bottom:1px solid #e6e6e6"></div>
          </div>-->
        </div>
      </div>
    </div>

    <!-- 관련 상품 -->
    <div class="lp-footer-background" v-if="related_product.length>0">
      <div class="container" style="padding: 20px 0 0 20px;">
        <div class="lp-footer-title">관련상품</div>
      </div>
      <CardProducts :shadow="false" :drag="true" :products="related_product"></CardProducts>
    </div>

    <div class="store-footer-container" style="padding-bottom: 80px;">
      <div class="columns is-multiline col-wrap" >
        <div class="column is-half">
          <div class="footer-title">원하시는 테마 서비스가 없으신가요?</div>
          <div class="footer-content">
            런치팩은 고객 분들이 원하는 서비스를 런칭할 수 있게 돕기 위해 주문제작 서비스를 운영하고 있습니다.
            원하시는 테마가 없고 좀 더 많은 기능을 원하시는 경우 주문제작 서비스를 바로 신청해 보세요!
          </div>
          <div class="subscription-btn btn" @click="modalOpen">주문제작 신청하기</div>
        </div>
        <div class="column is-half" >
          <img src="@/assets/theme-banner.png" alt="" style="background-size: cover; margin-bottom: -80px;">
        </div>

      </div>
    </div>


    <lp-theme-request-modal :is_open.sync="is_open" :title="themeCard.cardType" :type="themeCard.available"></lp-theme-request-modal>

    <sweet-modal ref="modal">
      <lp-service-inquiry-modal @closeModal="$refs.modal.close()"></lp-service-inquiry-modal>
    </sweet-modal>
  </div>

</template>

<script>
  import LpPaginate from "../component/LpPaginate";
  import LpThemeRequestModal from "../modal/LpThemeRequestModal";
  import Slick from 'vue-slick'
  import CardProducts from "../component/CardProducts";
  import LpServiceInquiryModal from "../modal/LpServiceInquiryModal";
  import { SweetModal } from 'sweet-modal-vue';
  import AnchorTab from "../module/AnchorTab";
  import UserAPIMixin from "../../mixins/UserAPIMixin";


  export default {
    mixins: [
      UserAPIMixin
    ],
    components: {
      LpServiceInquiryModal,
      CardProducts,
      LpThemeRequestModal,
      LpPaginate,
      Slick,
      SweetModal,
      AnchorTab
    },
    name: "storeDetail",
    data() {
      return {
        code: {
          '01': '하나(외환)카드',
          '03': '롯데카드',
          '04': '현대카드',
          '06': '국민카드',
          '11': 'BC카드',
          '12': '삼성카드',
          '14': '신한카드',
          '16': '농협카드',
          '21': '해외 VISA카드',
          '22': '해외마스터카드',
          '23': '해외 JCB카드',
          '26': '중국은련',
          '32': '광주은행',
          '33': '전북은행',
          '34': '하나은행',
          '35': '산업카드',
          '41': 'NH',
          '43': '씨티은행',
          '44': '우리은행',
          '48': '신협체크',
          '51': '수협',
          '52': '제주은행',
          '54': 'MG새마을금고체크',
          '55': '케이뱅크',
          '56': '카카오뱅크',
          '71': '우체국체크',
          '95': '저축은행체크'
        },
        showAnchor: false,
        // 모달 사용여부
        is_open: false,
        activetab: 1,
        // 상세 상품
        mainCard: '',
        themeCard: undefined,
        /*themeCard: {
          theme: '테마',
          cardType: '유희왕카드팩',
          title: 'Ecommerce Gem',
          content: '나는 테마상품 한줄평으로 글자수를 제한하여 작성하게 하고 모바일, PC 양쪽에서 모두 두줄 이하로 표시되도록 작성해야할텐데, 물론 쉽진 않을테야',
          available: '신청시 2주후 사용가능'
        },*/
        slickOptions: {
          centerMode: false,
          variableWidth: false,
          infinite: true,
          autoplay: false,
          centerPadding:'20px',
          slidesToShow: 3,
          slidesToScroll: 1,
          speed: 600,
          prevArrow: '<div class="arrow-left"><img class="arrow-img" src="/static/img/img_arrow_left.png" @click="prev"></div>',
          nextArrow: '<div class="arrow-right"><img class="arrow-img" src="/static/img/img_arrow_right.png" @click="next"></div>',
          responsive: [
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1,
                dots: false,
                arrows: false
              }
            }
          ]
        },
        // 전체 카운트 수
        allCount: 0,
        // 문의 필터
        filtered: {
          cur_page: 1,
          page_num:1,
          total_page:1,
          page_length: 10
        },
        // 문의 남기기
        writeInquiry: {
          id: 0,
          title: "",
          question_content: "",
          product_id: 0
        },
        // 문의 목록
        themeInquiry: [],
        // 연관 상품
        related_product: []
      }
    },
    watch:{
      '$route'(n){
        //this.themeRequest();
        if(n.query.id) {
          this.getProductDetail();
          this.getProductInquiry();
          this.getRelationTheme();
        }
      }
    },
    created() {
      if(this.$route.query.prev) {
        setTimeout(() => {
          this.toast('입력하신 카드정보가 잘못되었습니다. 다시 입력하시기 바랍니다.');
        },100);
        setTimeout(() => {
          this.$router.push(this.$route.path+'?id='+this.$store.getters.basket.product_id);
        },1700);
      }
      if(this.$route.query.id) {
        this.getProductDetail();
        this.getProductInquiry();
        this.getRelationTheme();

        if(this.isMobile) {
          this.slickOptions.variableWidth = false;
          this.slickOptions.centerMode = false;
          this.slickOptions.autoplay = true;
        }
      }
    },
    mounted () {
      //this.themeRequest();
      this.sbRequest();
    },
    computed: {
      computedTabTop () {
        if (window.innerWidth < 600) {
          return 100
        }
        else return 70
      },
      anchorHeadStyle() {
        return {
          // padding: '0 80px',
          fontFamily: 'Arita Dotum Medium',
          fontSize: '14px'
        }
      },
      slickImg() {
        let width = window.innerWidth - 24.5;
        let height = width * 1.566;
        if(window.innerWidth>500) {
          width = 332;
          height = 520;
        }

        return {
          width: width+'px',
          height: height+'px'
        }
      },
      contentSlideStyle() {
        return {
          width: '1056px',
          height: this.slickImg.height
        }
      }
    },
    methods: {
      sbRequest() {
        let basket = this.$store.getters.basket;
        let result = JSON.parse(this.$route.query.result);
        let resultCode = result.resultCode;
        if (resultCode !== '00' && resultCode !== '0000') {
          this.toast('입력하신 카드정보가 잘못되었습니다. 다시 입력하시기 바랍니다.');
          this.$router.replace(`/service_request`);
          return;
        }

        result.buyerEmail = basket.email;

        this.setLoading('서비스 개설중입니다');
        this.request.user.post(`ui/super_builder/temporary/${basket.service.id}/service_create`, {
          result: result
        }).then(res => {
          if (res.status === 200) {
            this.clearLoading();
            this.$store.commit('setAccountDone', {
              key: basket.service.key,
              name: basket.service.name,
              price: Number(result.TotPrice),
              credit: `${this.code[result.CARD_Code]} ${result.CARD_Num}`
            });
            this.$store.commit('clearBasket');
            this.$router.push('/service_request_done');
          }
        }).catch(err => {
          this.clearLoading();
          if (!err.response.data.message)
            console.log(err.response.data.detail);
          else
            console.log(err.response.data.message);
        });
      },
      themeRequest() {
        // 빌링 입력 후 리다이렉션
        if(this.$route.query.result) {
          let basket = this.$store.getters.basket;
          let result = JSON.parse(this.$route.query.result);
          let resultCode = result.resultCode;
          if (resultCode !== '00' && resultCode !== '0000') {
            this.toast('입력하신 카드정보가 잘못되었습니다. 다시 입력하시기 바랍니다.');
            this.$router.replace(`/store/detail?id=${basket.product_id}`);
            return;
          }
          this.$store.commit('setLoading', true);
          let url = `user/${this.$store.getters.user.user_id}/launchpack/theme/request`;


          result.buyerEmail = basket.email;

          this.$axios.post(url, {
            inquiry: basket,
            result: result
          }).then(res => {
            if (res.status === 200) {
              setTimeout(()=> {
                this.is_open = false;
                this.toast('신청이 완료되었습니다.');
                this.$store.commit('setLoading', false);

                this.$store.commit('clearBasket');
                if(this.$store.getters.isLogin){
                  this.$router.push('/mypage/mysite');
                }
                this.setGa(`${this.themeCard.title} 이용 신청 완료`, '응답', `${this.themeCard.title} 이용 신청 완료`);
              },15000);

            }
          }).catch(err => {
            this.$store.commit('setLoading', false);
            if (!err.response.data.message)
              console.log(err.response.data.detail);
            else
              console.log(err.response.data.message);
          });
        }
      },
      clickTab(index, title) {
        this.activetab=index;
        this.setGa(`${this.themeCard.title} 상세 탭 (${title})`, '클릭', title);
      },
      clickDemo() {
        this.setGa(`${this.themeCard.title} 상세 (테마 서비스 데모)`, '클릭', '테마 서비스 데모');
      },
      modalOpen(){
        this.$refs.modal.open();
      },
      checkInquiry() {
        if(!this.isLogin) {
          this.toast('로그인이 필요합니다.');
          return;
        }
      },
      clickInquiry(inquiry) {
        if(inquiry.answerer) inquiry.selected?inquiry.selected=false:inquiry.selected=true
      },
      searchInquiry(e) {
        this.filtered.search_keyword = e.target.value;
        this.getProductInquiry();
      },
      next() {
        this.$refs.slick.next();
      },

      prev() {
        this.$refs.slick.prev();
      },
      // 이용 신청하기
      clickRequest() {
        this.setGa(`${this.themeCard.title} 상세 (이용 신청하기)`, '클릭', '이용 신청하기');
        if(this.isLogin){
          this.is_open = true;
        }
        else {
          //this.$root.$emit('login');
          this.toast('회원가입 후 결제가 가능합니다.');
          this.$store.commit('setPrevPath', this.$route.fullPath);
          this.$router.push('/signup');
        }
        //this.is_open = true;
      },
      // 데이터 초기화
      initWriteInquiry() {
        this.writeInquiry = {
          id: 0,
          title: "",
          question_content: "",
          product_id: this.$route.query.id
        }
      },
      // 문의 제출
      saveProductInquiry() {
        let user_id = this.$store.getters.user.user_id;
        if( !user_id ) {
          this.toast('로그인하시면 문의글을 남길수 있습니다.');
          return;
        }
        if( !this.writeInquiry.question_content ) {
          this.toast('문의글을 작성하세요.');
          return;
        }

        this.$axios.post(`user/${user_id}/product/inquiry`, this.writeInquiry).then(res=>{
          if (res.status === 200) {
            this.setGa(`${this.themeCard.title} 상세 (문의제출)`, '클릭', '문의제출');
            this.toast('문의 등록되었습니다.');
            this.getProductInquiry();
            this.initWriteInquiry();
          }
        });
      },
      // 할인 금액
      discountPrice(product){
        return product.price - (product.price * product.discount_rate / 100);
      },
      /*discountCard(price) {
        if (price.discountPrice !== '') {
          return price.price - (price.price * price.discountPrice / 100)
        }
      },*/
      // 문의 상태
      contentAnswer(name) {
        if (!name) return '답변예정';
        else return '답변완료';
      },
      contentImg(img){
        return{
          backgroundImage:`url(${img})`,
          width:'',
          height:''
        }
      },
      contentStatus(status){
        if(status===1){
          return '바로이용가능'
        }else if(status===2){
          return '신청 후 2주 뒤 이용가능'
        }
      },
      // 상품 상세
      getProductDetail() {
        this.$axios.get(`public/launchpack/theme/${this.$route.query.id}`).then(res=>{
          if(res.status === 200) {
            this.mainCard = res.data.imgs[0];
            res.data.imgs = res.data.imgs.splice(1);
            res.data.imgs = res.data.imgs.concat(res.data.imgs);
            this.themeCard = res.data;

            // 데이터 초기화
            this.initWriteInquiry();
            this.themeCard.params.forEach(param => {
              if(param.name.indexOf("신청시 이용가능")>-1){
                if(param.value_select.length>0) {
                  this.themeCard.available =this.findItem(param.choice_items,'id', param.value_select[0]).name;
                }
              }
            });
            this.showAnchor = true;
          }
        });
      },
      // 상품 문의 리스트
      getProductInquiry() {
        this.$axios.get(`public/launchpack/theme/${this.$route.query.id}/inquiry`,{
          params: this.filtered
        }).then(res=>{
          if(res.status === 200) {
            this.themeInquiry = res.data.data;
            this.filtered.page_length = res.data.page_length;
            this.filtered.cur_page = res.data.cur_page;
            this.filtered.total_page = res.data.total_page;
            this.allCount = res.data.count;
            //this.filteredCount = res.data.count;
          }
        });
      },
      // 연관상품 3개 가져오기
      getRelationTheme() {
        this.$axios.get(`public/launchpack/theme/${this.$route.query.id}/relation`).then(res=>{
          if(res.status === 200) {
            this.related_product = res.data.data;

            this.related_product.forEach(product=>{
              product.params.forEach(param=>{

                if(param.name.indexOf("신청시 이용가능")>-1){
                  product.available = param.value_name;
                }
              });
            });
          }
        });
      }
    }

  }
</script>

<style scoped>

  .footer-title{
    font-size: 24px;
    padding-top: 38px;
    /*padding-left: 49px;*/
    font-weight: 500;
    margin-left: 278px;
  }
  .footer-content{
    font-size: 12px;
    color:#a0a0a0;
    line-height: 24px;
    white-space: pre-line;
    margin-left: 278px;
  }
  .subscription-btn{
    cursor:pointer;
    text-align: center;
    color: white;
    width: 160px;
    height: 48px;
    background-color: #ff6600;
    border-radius: 3px;
    padding: 14px 0;
    margin-left: 278px;
    margin-top: 30px;
  }

  .subscription-btn a{
    text-decoration: none;
    color:#ffffff;
    font-weight: 500;
  }

  .store-footer-container{
    width: 100%;
    background-color: #fff;
    display: flex;
    margin: 40px auto 0 auto;
  }
  .col-wrap{
    width: 100%;
    margin-bottom: -16px !important;
  }

  .img{
    /*width: 344px;
    height: 194px;*/
    background-color: navy;
  }

  .slick-img {
    /*width: 292px;*/
    width: 100%;
    height: 520px;
    padding: 0;
  }

  .card-label {
    position: absolute;
    bottom: 8px;
  }

  .theme-card{
    height: 100%;
    position: relative;
  }
  .theme-card-form{
    height: 18px;
    font-size: 12px;
    display: flex;
    align-items: center;
  }
  .card-title{
    text-align: left;
    font-size: 24px;
    font-weight: 700;
    margin: 2px 0 10px 0;
  }
  .card-comment{
    font-size: 14px;
    text-align: left;
    line-height: 24px;
    margin-bottom: 24px;
  }
  .card-available{
    /*text-align: left;*/
    font-size:12px;
    font-weight: 500;
    padding: 6px 12px;
    background-color: #f8d053;
    border-radius: 3px;
  }
  .product-price{
    /*width: 344px;*/
    height: 194px;
    /*margin-left: 370px;*/
  }
  .price{
    font-weight: bold;
    margin-right: 8px;
    color: #a0a0a0;
    text-decoration: line-through;
  }
  .counselling{
    font-size:12px;
    color:#a0a0a0;
    margin-top: 8px;
  }
  .product-sub {
    /*text-align: right;*/
    background-color:#ff6600;
    border:1px solid #ff6600;
    width: 140px;
    height: 48px;
    line-height: 46px;
    font-size:14px;
    font-weight: 500;
    color:#ffffff;
    border-radius: 3px;
    margin-bottom:8px;
    padding-left: 22px;
    padding-right: 22px;
    margin-top: 8px;
    text-align: center;
  }
  .product-demo {
    /*text-align: right;*/
    border:1px solid #ff6600;
    width: 140px;
    height: 48px;
    line-height: 46px;
    font-size:14px;
    font-weight: 500;
    color:#ff6600;
    border-radius: 3px;
    text-align: center;
    letter-spacing: -0.6px;
  }

  .lp-section-container{
    margin:40px auto;
    font-size:0.9rem;
    color:#888;
  }
  /*.tabs{
    overflow:hidden;
    margin-left: 20px;
    margin-bottom:-2px;
    width: 208px;
  }*/
  .lp-tabs{
    padding:0;
    margin-top:24px;
  }

  .lp-tabs .lp-tab{
    /*width: 167px;*/
    width: 20%;
    padding: 10px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.2s;
    border-right: none;
    font-weight: bold;
    font-size: 14px;
    color:black;
    border-bottom: 1px solid #eaeaea;
  }
  /* Styling for active tab */
  .lp-tabs .lp-tab.active {
    background-color: #fff;
    cursor: default;
    border: 1px solid #ccc;
    border-bottom:none;
  }

  /* Style the tab content */
  .tabcontent {
    /*padding: 30px;*/
    border:none;
    border-top: 1px solid #ccc;
    /*width: 100%;*/
    /*display: flex;*/
  }

  .tabcontent1-wrap{
    width: 75%;
    display: flex;
  }


  .inquiry{
    text-align: left;
    font-size: 18px;
    color:#303030;
    font-weight: 500;
    margin-top: 31px;
    /*width: 188px;*/
    /*float: left;*/
  }
  .input-search{
    position: relative;
    /*float: left;*/
    width: 344px;

  }

  input::-ms-input-placeholder {
    color: #a0a0a0;
    padding-left: 16px;
  }
  input::-webkit-input-placeholder{
    color: #a0a0a0;
    padding-left: 16px;

  }
  input::-moz-placeholder{
    color: #a0a0a0;
    padding-left: 16px;
  }

  .response-container{
    width: 100%;
    border:1px solid #e6e6e6;
    border-radius: 3px;
    margin-top: 16px;

  }

  .response-container:hover {
    background-color: #e6e6e6;
  }

  .response-box{
    text-align: left;
    color:#000;
    margin:16px 9px 10px 16px;
    cursor: pointer;
  }

  .admin-response-box{
    padding: 20px 40px;
    color:#000;
  }
  .admin-response-box .user-name {
    color:#ff6600;
  }

  .user-name{
    display: inline-block;
  }
  .dateTime{
    font-size:12px;
    color:#a0a0a0;
    display: inline-block;
  }

  .answer-box{
    width: 67px;
    height: 32px;
    border-radius: 3px;
    background-color: #f7f7f7;
    display: inline-block;
    text-align: center;
    line-height: 32px;
  }

  .answer1{
    width: 67px;
    height:32px;
    border-radius: 3px;
    background-color:#f7f7f7;
    font-size: 12px;
    color:#a0a0a0;
    /*text-align: right;*/
  }
  .answer2{
    background-color:#ffffff;
    color:#0ead9d;
    border:1px solid #e6e6e6;
    font-size:12px;
    width: 67px;
    height: 32px;
  }
  .answer3{
    background-color:#ffffff;
    color:#0ead9d;
    border:1px solid #e6e6e6;
    font-size:12px;
    width: 67px;
    height: 32px;
  }

  .page_num {
    color: #999;
  }
  .current {
    color: red;
  }
  .page-nation {
    padding: 0;
    list-style-type: none;
  }
  .page-nation li {
    display: inline;
    margin: 5px 5px;
  }

  .page_num.first::after {
    content:'...'
  }

  .page_num.last::before {
    content:'...'
  }

  .pageList{
    list-style: none;
    display: inline;
  }
  .pagination-wrap{
    margin-top: 30px;
  }
  .writeInquire{
    text-align: left;
    margin-bottom:10px;
    color:#303030;
    font-weight: 500;
  }
  .writeTxt{
    width: 100%;
    height:96px;
  }
  .writeTxt::-ms-input-placeholder{
    text-align: left;
  }
  .writeTxt::-webkit-input-placeholder{
    text-align: left;
  }
  .writeTxt::-moz-input-placeholder{
    text-align: left;
  }

  .submit-btn{
    background-color:#ff6600;
    text-align: center;
    line-height: 40px;
    width: 72px;
    height: 40px;
    border-radius: 3px;
    color:#fff;
    font-weight: 700;
    cursor: pointer;
  }
  .cardNum{
    border-radius: 3px;
    margin-top: 24px;
    background-color: #ffffff;
    border:1px solid #eaeaea;
    padding:8px;
    padding-left: 12px;
    font-size:14px;
  }
  .supply{
    height: 100%;
    border-radius: 3px;
    background-color: #ffffff;
    border:1px solid #eaeaea;
    margin-top: 11px;
    font-size: 14px;
  }
  .lp-footer-background{
    background-color:#606060;
  }
  .lp-footer-title{
    font-weight: 500;
    color:#fff;
    text-align: left;
  }
  .card-img{
    position: relative;
    width: 100%;
    height:193px;
    border-radius: 3px 3px 0 0;
    background-color: white;
  }
  .cardAvailable{
    position: absolute;
    left:16px;
    top:16px;
    border-radius: 3px;
    background-color:rgba(0,0,0,0.6);
    padding: 7px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
  }
  .card-body{
    width: 100%;
    height: 193.5px;
    background-color:#fff;
    border-radius: 0 0 3px 3px;
    padding: 16px;
  }
  .card-title-footer{
    font-size: 18px;
    font-weight: 500;
  }
  .card-type{
    /*margin-right: 16px;*/
    font-size: 14px;
    color:#a0a0a0;

  }
  .card-txt{
    font-size: 14px;
    text-align: left;
    line-height: 25px;
    color:#606060;
    margin-bottom:12px;
    height: 75px;
    overflow: hidden;
    white-space: normal;
    word-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  .card-price{
    text-align: left;
    font-weight: bold;
  }
  .card-demo{
    width: 76px;
    height: 40px;
    font-size: 13px;
    border-radius: 3px;
    color:#ff6600;
    border:1px solid #eaeaea;
    line-height: 38px;
    text-align: center;
  }
  .card-num{
    text-align: left;
    width: 60px;
    height: 18px;
    font-size: 12px;
    color:#a0a0a0;
    margin-top: 2px;
  }
  .status1{
    font-size: 12px;
    font-weight: 500;
    color:#fff;
    line-height: 34px;

  }
  .status2{
    width: 125px;
    height: 34px;
    border-radius: 3px;
    font-size: 12px;
    font-weight: 500;
    color:#fff;
    line-height: 34px;
  }

  .content-slide{
    width: 100%;
    height: 520px;
    /*border: 1px solid black;*/
    margin-top: 24px;
  }

  /*.slick-img{
    width: 300px;
    height: 520px;
    !*background-color: yellow;*!
    padding: 0 10px;
  }*/
  .theme-rule{
    white-space: pre-line;
    font-size: 13px;
    line-height: 28px;
  }

  .search-div {
    border: 1px solid #eaeaea;
    align-items: center;
    padding-left: 19px;
    border-radius: 3px;
  }

  .search-icon {
    width: 16px;
    height: 16px;
    margin-right: 16px;
  }

  .search{
    width: 100%;
    height: 40px;
    outline: none;
    border-radius: 3px;
    background-color: #ffffff;
    border: 0;
    font-size:13px;
  }

  .lp-allcount {
    font-size: 18px;
    font-weight: bold;
    color: #303030;
    line-height: 40px;
  }

  .lp-floating {
    position: fixed;
    left: 0;
    bottom: 0;
    height: 56px;
    width: 100%;
    background: white;
    z-index: 1000;
  }

  /* 가격 상세 정보 */

  .discount-price{
    font-weight: bold;
    font-size: 18px;
  }

  .discountTitle{
    color:#cc4945;
    font-size: 12px;
    font-weight: bold;
  }

  .discount-price,
  .discountTitle,
  .counselling,
  .lp-btn-group {
    display: flex;
    justify-content: flex-end;
  }



  .lp-floating-menu {
    width: 50%;
    border: 1px solid #ff6600;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff6600;
    font-size: 14px;
    margin: 0 4px;
    border-radius: 3px;
  }

  .lp-floating-menu:first-child {
    background-color: #ff6600;
    color: white;
  }

  @media(max-width:768px) {
    .lp-store-detail {
      padding: 0 24px;
    }
    .lp-store-price-detail {
      padding: 0 24px;
    }
    .card-label {
      bottom: -41px;
    }
  }

  @media(max-width:601px) {
    .card-img {
      border-radius: 0;
    }
    .product-price {
      height: unset;
    }
    .discount-price,
    .discountTitle,
    .counselling,
    .lp-btn-group {
      display: flex;
      justify-content: unset;
    }
    .lp-tabs .lp-tab {
      padding: 5px;
      font-size: 11px;
    }
  }

  @media(min-width:1024px) and (max-width: 1440px){
    .pro-num-container{
      margin-left:90px;
    }
    .pro-latest{
      margin-right: 135px;
    }
    .footer-title{
      margin-left: 100px;

    }
    .footer-content{
      margin-left: 100px;

    }
    .subscription-btn{
      margin-left: 100px;

    }
  }

  @media(min-width: 768px) and (max-width: 1023px){
    .pro-num-container{
      margin-left: 60px;
    }
    .pro-latest{
      margin-right:20px;
    }
    .footer-title{
      margin-left: 49px;

    }
    .footer-content{
      margin-left: 49px;

    }
    .subscription-btn{
      margin-left: 49px;

    }
  }

  @media(min-width:320px) and (max-width:767px){
    .pro-num-container{
      width: 100%;
      margin-left: 0;
    }
    .pro-latest{
      margin-right: 0;
      width: 25%;
    }
    .footer-title{
      margin-left: 49px;

    }
    .footer-content{
      margin-left: 49px;

    }
    .subscription-btn{
      margin-left: 49px;

    }
  }


  @media(max-width:320px) {
    .content {
      padding: 0;
    }
  }

  @media(max-width:1024px) {
    .slick-img{
      width: 100%;
      height: 480px;
      padding:0;
    }
  }
  @media(min-width:501px) {
    .slick-img-outer {
      padding: 0 8px;
    }
  }

</style>
